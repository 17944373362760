export const content = {
  home: {
    title: "Home",
    description:
      "Helping customers adopt cloud technologies since 2018. Our team of highly skilled DevOps engineers is dedicated to assisting with digital transformations to cloud services like GCP, AWS, or Azure.",
    bg: "/clouds.jpg",
  },
  services: {
    title: "Cloud Services",
    description:
      "We specialize in assisting companies with migrations from on-premises systems to cloud environments. Our expertise extends to establishing processes like CI/CD and adopting cloud native services like Kubernetes. We also assist with Infrastructure as Code automation using HashiCorp toolsets.",
    bg: "computerdesk.jpg",
  },
  about: {
    title: "About Us",
    description:
      "Marko DevOps has been at the forefront of the cloud technology revolution since 2018. Our team of seasoned DevOps engineers brings over a decade of industry experience to every project. We're committed to helping our customers navigate the complexities of digital transformation and achieve their cloud ambitions.",
    bg: "projectplan.jpg",
  },
  blog: {
    title: "Blog",
    description: "Check out our blog on Medium.",
    bg: "code.jpg",
  },
  contact: {
    title: "Contact Us",
    description:
      "We would love to hear from you. Please reach out to us with any questions or comments.",
  },
};
