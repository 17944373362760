import "./App.css";
import NavigationBar from "./components/NavigationBar";
import Section from "./components/Section";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { content } from "./components/content.js";
import Footer from "./components/Footer";
import ContactForm from "./components/ContactForm";
import BlogSection from "./components/BlogSection";

function App() {
  const theme = createTheme({
    palette: {
      mode: "dark", // Set the overall color mode
      primary: {
        main: "#0a0a0a", // Set the primary color
      },
      custom: {
        white: "#fff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar />
      <Section
        name="home"
        title={content.home.title}
        description={content.home.description}
        bgImage={content.home.bg}
      />
      <Section
        name="about"
        title={content.about.title}
        description={content.about.description}
        bgImage={content.about.bg}
      />
      <Section
        name="services"
        title={content.services.title}
        description={content.services.description}
        bgImage={content.services.bg}
      />
      <Section
        name="blog"
        title={content.blog.title}
        description={content.blog.description}
        bgImage={content.blog.bg}
      >
        <BlogSection />
      </Section>
      <Section
        name="contact"
        title={content.contact.title}
        description={content.contact.description}
      >
        <ContactForm />
      </Section>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
