import * as React from "react";

import { Link } from "react-scroll";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Twitter, LinkedIn, GitHub } from "@mui/icons-material";

const NavigationBar = () => {
  const links = {
    twitter: "https://twitter.com/markovlahovic07",
    linkedin: "https://www.linkedin.com/in/marko-vlahovic-devops/",
    github: "https://github.com/marko7460",
  };
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" component="div" flexGrow={1}>
          Marko DevOps Services
        </Typography>
        <Box sx={{ ml: "auto" }}>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Home
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About Us
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Services
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="casestudies"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Case Studies
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Testimonials
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="blog"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Blog
            </Link>
          </Button>
          <Button color="inherit">
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact Us
            </Link>
          </Button>
        </Box>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Twitter"
          component="a"
          href={links.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="LinkedIn"
          component="a"
          href={links.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="GitHub"
          component="a"
          href={links.github}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHub />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
export default NavigationBar;
