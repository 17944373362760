import { TextField, Button, Box, Typography } from "@mui/material";

const ContactForm = () => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      padding={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography variant="h4" component="div">
        Contact Us
      </Typography>
      <TextField label="Name" variant="outlined" />
      <TextField label="Email" variant="outlined" />
      <TextField label="Subject" variant="outlined" />
      <TextField label="Message" variant="outlined" multiline rows={4} />
      <Button variant="contained" color="primary" type="submit">
        Send
      </Button>
    </Box>
  );
};

export default ContactForm;
