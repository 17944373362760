import React, { useState } from "react";
import {
  Paper,
  Link,
  List,
  ListItem,
  ListItemButton,
  TablePagination,
} from "@mui/material";
const blogs = [
  {
    title: "Writing google terraform code with GitHub Copilot",
    link: "https://medium.com/@markodevops/writing-google-terraform-code-with-github-copilot-78cfe51c0291?source=rss-807e87607cd2------2",
  },
  {
    title:
      "Deploy a GCP Foundation infrastructure using Terraform Cloud deployment and GCP Workload Identity",
    link: "https://medium.com/@markodevops/deploy-a-gcp-foundation-infrastructure-using-terraform-cloud-deployment-and-gcp-workload-identity-532bdea676d2?source=rss-807e87607cd2------2",
  },
  {
    title: "How ChatGPT Helped Shape My Website (markodevops.com)",
    link: "https://medium.com/@markodevops/how-chatgpt-helped-shape-my-website-markodevops-com-51d637a5ae79",
  },
];

const BlogSection = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: "60%" }}>
      <List>
        {blogs
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((blog, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <Link
                  href={blog.link}
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                >
                  {blog.title}
                </Link>
              </ListItemButton>
            </ListItem>
          ))}
        <TablePagination
          component="div"
          count={blogs.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </List>
    </Paper>
  );
};
export default BlogSection;
