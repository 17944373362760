import { Box, Typography, IconButton } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  const socialLinks = {
    twitter: "https://twitter.com/markovlahovic07",
    linkedin: "https://www.linkedin.com/in/marko-vlahovic-devops/",
    github: "https://github.com/marko7460",
  };

  return (
    <Box
      sx={{
        backgroundColor: "grey.900",
        color: "white",
        padding: "2rem",
        marginTop: "auto",
        textAlign: "center",
      }}
    >
      <Typography variant="body1">
        © 2023 Marko DevOps Services. All rights reserved.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          marginTop: "1rem",
        }}
      >
        <IconButton
          color="inherit"
          component="a"
          href={socialLinks.github}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon />
        </IconButton>
        <IconButton
          color="inherit"
          component="a"
          href={socialLinks.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          color="inherit"
          component="a"
          href={socialLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
