import React from "react";
import { Slide, Fade, Paper, Grid, Typography, useTheme } from "@mui/material";
import { useInView } from "react-intersection-observer";

const Section = ({ name, title, description, bgImage, children }) => {
  const { ref, inView } = useInView({
    threshold: 0.6, // section is in view when it's 10% visible
  });

  const theme = useTheme();

  return (
    <div id={name} style={{ padding: theme.spacing(0.1, 0) }} ref={ref}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          paddingTop: "70px",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundColor: bgImage ? "transparent" : "grey.500",
          position: "relative", // Add this line
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.35)", // This is the overlay
            zIndex: 1, // Set z-index to 1
          }}
        />
        <Grid container spacing={1} style={{ zIndex: 2 }}>
          <Grid item xs={12} md={4}>
            <Slide direction="right" in={inView} mountOnEnter unmountOnExit>
              <Typography
                variant="h2"
                component="div"
                color={theme.palette.custom.white}
              >
                {title}
              </Typography>
            </Slide>
          </Grid>
          <Grid item xs={12} md={8}>
            <Fade in={inView} mountOnEnter unmountOnExit>
              <Typography
                variant="h4"
                component="div"
                color={theme.palette.custom.white}
              >
                {description}
              </Typography>
            </Fade>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Section;
